import React from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route
} from 'react-router-dom';
import OtherRoute from './OtherRoute';
import Entry from '../components/Entry';
import About from '../components/About';
import Projects from '../components/Projects';
import SharpRogue from '../components/SharpRogue';
import SchedulerBot from '../components/SchedulerBot';
import Creditus from '../components/Creditus';
import Contact from '../components/Contact';
import Talks from '../components/Talks';

const MainRouter = () => (
    <Router>
        <Switch>
            <Route exact path='/' component={Entry}/>
            <OtherRoute path='/about' component={About}/>
            <OtherRoute exact path='/projects' component={Projects}/>
            <Route path='/projects/sharp-rogue' component={SharpRogue}/>
            <Route path='/projects/scheduler-bot' component={SchedulerBot}/>
            <Route path='/projects/creditus' component={Creditus}/>
            <OtherRoute path='/contact' component={Contact}/>
            <OtherRoute path='/talks' component={Talks}/>
        </Switch>
    </Router>
);

export default MainRouter;