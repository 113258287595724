import React from 'react';

const Projects = ({history}) => {
    return (
        <div className="projects">
            <div className="list">
                <div className="item" onClick={()=>{history.push('/projects/sharp-rogue')}}>
                    <h1>SHARP ROGUE</h1>
                    <p>terminal rogue like rpg</p>
                </div>
                <div className="item" onClick={()=>{history.push('/projects/scheduler-bot')}}>
                    <h1>SCHEDULER BOT</h1>
                    <p>telegram chat-bot to never forget</p>
                </div>
                <div className="item" onClick={()=>{history.push('/projects/creditus')}}>
                    <h1>CREDITUS</h1>
                    <p>platform that helps MFF UK students</p>
                </div>
            </div>
            <div className="dots"/>
        </div>
    );
};

export default Projects;