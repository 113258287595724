import React, {useEffect} from 'react';
import Header from './Header';

const Creditus = () => {
    useEffect(() => {
        document.body.className = "black-back";
        return () => {
           document.body.className = "";
        }
    }, []);
    return (
        <div
            className="project-page"
            id="creditus"
        >
            <Header/>
            <div className="project-title">
                <h1>CREDITUS</h1>
                <p>platform that helps MFF UK students</p>
            </div>
            <div id="creditus-img-1"/>
            <div className="block">
                <h1>Achieve</h1>
                <p>
                    See how close you are to finishing your specialisation...or how far.
                </p>
            </div>
            <div id="creditus-img-2"/>
            <div className="block">
                <h1>Plan</h1>
                <p>
                    You can add a course and see how it affects your completion requirements.
                </p>
            </div>
            <a href="https://creditus.zavidnyi.com">creditus</a>
        </div>
    );
};
export default Creditus;