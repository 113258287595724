import React, {useState} from 'react';

const Entry = ({history}) => {
    const isMobile = window.innerWidth <= 480;
    console.log(window.innerWidth);
    const [showAbout, setShowAbout] = useState(false);
    const [aboutFocused, setAboutFocused] = useState(false);
    const [showProjects, setShowProjects] = useState(false);
    const [projectsFocused, setProjectsFocused] = useState(false);
    const [showContact, setShowContact] = useState(false);
    const [contactFocused, setContactFocused] = useState(false);
    const [mobileExposed, setMobileExposed] = useState(false);
    return (
            isMobile ? 
            <div className="mobile-entry" onClick={()=>setMobileExposed(!mobileExposed)}>
                    <div className={mobileExposed ? "hidden" : "exposed"}>
                        <h1>{mobileExposed ? <span className="blank" id="abt" onClick={()=>history.push("/about")}>Hello.</span> : "Hello."}</h1>
                        <h1 className="delay-2">{mobileExposed ? <span className="blank" id="prj" onClick={()=>history.push("/projects")}>I am</span> : "I am"}</h1>
                        <h1 className="delay-4">{mobileExposed ? <span className="blank" id="cnt" onClick={()=>history.push("/contact")}>Zavidnyi</span> : "Zavidnyi"}</h1>
                    </div>
                    <div id="tap">tap anywhere</div>
            </div> :
            <div className="entry">
                <div 
                    className="exposed"
                    onClick={()=>setShowAbout(!showAbout)}
                >
                    <span className={(showAbout && "strike") || "strike-back"}>Hello.</span>
                </div>
                <div 
                    className="hidden" 
                    id="about"
                    onMouseEnter={() => {
                        if (showAbout) setAboutFocused(true);
                    }}
                    onMouseLeave={() => {
                        if (showAbout) setAboutFocused(false);
                    }}
                    onClick={()=>{
                        if (showAbout)
                            history.push('/about');
                    }}
                >
                {showAbout && ((aboutFocused && "4b0u1") || "About")}
                </div>
                <div 
                    className="exposed"
                    onClick={()=>setShowProjects(!showProjects)}
                >
                    <span className={(showProjects && "strike") || "strike-back"}>I am</span>
                </div>
                <div 
                    className="hidden" 
                    id="proj"
                    onMouseEnter={() => {
                        if (showProjects) setProjectsFocused(true);
                    }}
                    onMouseLeave={() => {
                        if (showProjects) setProjectsFocused(false);
                    }}
                    onClick={()=>{
                        if (showProjects)
                            history.push('/projects');
                    }}
                >
                    {showProjects && ((projectsFocused && "Pr0j5c1s") || "Projects")}
                </div>
                <div 
                    className="exposed"
                    onClick={()=>setShowContact(!showContact)}
                >
                    <span className={(showContact && "strike") || "strike-back"}>Zavidnyi</span>
                </div>
                <div 
                    className="hidden" 
                    id="contact"
                    onMouseEnter={() => {
                        if (showContact) setContactFocused(true);
                    }}
                    onMouseLeave={() => {
                        if (showContact) setContactFocused(false);
                    }}
                    onClick={()=>{
                        if (showContact)
                            history.push('/contact');
                    }}
                >
                    {showContact && ((contactFocused && "C0n14c1") || "Contact")}
                </div>
            </div>
    );
};

export default Entry;