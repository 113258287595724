import React, {useState} from 'react';

const Contact = () => {
    const [copy, setCopy] = useState(false);
    const isMobile = window.innerWidth <= 480;
    return (
        <div id="contact-page">
            <h1>let’s do great things <b>together</b></h1>
            <div id="smile"/>
            <h2>Contact</h2>
            <p>
                You want to collabarate with me? (I do too!). Don't be shy and reach out to me on any platform below:
            </p>
            <a 
                onClick={()=>{
                    navigator.clipboard.writeText("hello@zavidnyi.com");
                    if (isMobile) {
                        setCopy(true);
                        setTimeout(()=>setCopy(false), 1000);
                    }
                }}
                onMouseEnter={()=>setCopy(true)}
                onMouseLeave={()=>setCopy(false)}
            >
                {copy ? (isMobile ? "copied" :"copy") + " to clipboard" : "hello@zavidnyi.com"}
            </a>
            <a href="https://www.instagram.com/zavdnyi/">instagram</a>
            <a href="https://github.com/zavidnyi">github</a>
            <a href="https://www.linkedin.com/in/ilya-zavidny-525690166/">linkedin</a>
        </div>
    );
};

export default Contact;