import React, {useEffect} from 'react';
import Header from './Header';

const SchedulerBot = () => {
    useEffect(() => {
        document.body.className = "black-back";
        return () => {
           document.body.className = "";
        }
    }, []);
    return (
        <div
            className="project-page"
            id="scheduler-bot"
        >
            <Header/>
            <div className="project-title">
                <h1>SCHEDULER BOT</h1>
                <p>telegram bot to never forget</p>
            </div>
            <div id="bot-msg-1"/>
            <div className="block">
                <h1>Schedule</h1>
                <p>
                    Organize your life in the comfort of the chatting app and free up your mind 
                </p>
            </div>
            <div id="bot-msg-2"/>
            <div className="block">
                <h1>Get Notified</h1>
                <p>
                    Receive notifications about upcoming events and never be late again
                </p>
            </div>
            <a href="https://github.com/zavidnyi/telegram-scheduler-bot">github</a>
        </div>
    );
};

export default SchedulerBot;