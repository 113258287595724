import React from 'react';
import resume from '../files/resume.pdf';

const About = () => {
    return (
        <div className="about">
            <h1>
                i study, code & explore my passions
            </h1>
            <div className="images">
                <div id="image-1"/>
                <div id="image-2"/>
                <div id="image-3"/>
            </div>
            <h2>
                About
            </h2>
            <p>
                <b>Hi, I’m Ilia Zavidnyi. I’m a CS student, creating software that I enojoy.</b> <br/>
                <br/>
                My interests include (but are not limited to): Web Development, Cryptogrpahy and networks. <br/>
                <br/>
                Fueled by high energy levels and boundless enthusiasm, I’m easily inspired and more than willing to follow my fascinations wherever they take me. I’m a passionate, expressive, multi-talented spirit with a natural ability to entertain and inspire. I’m never satisfied to just come up with ideas. Instead, I have an almost impulsive need to act on them. <br/>
                <br/>
                My abundant energy fuels me in the pursuit of many interests, hobbies, and areas of study.<br/>
                <br/>
                My personality type is <a href="https://www.16personalities.com/entj-personality" target="_blank" rel="noreferrer">ENTJ</a>. I am currently doing research.<br/>
                <br/>
                You can read about my skills and experience in the pdf attached below:<br/>
            </p>
            <a id="link" href={resume} target="_blank" rel="noreferrer">my resume (264 kb)</a>
        </div>
    );
};

export default About;