import React from "react";
import { Route } from "react-router-dom";
import Header from '../components/Header';

const PublicRoute = ({
    component:Component,
    ...rest
}) => {
    return (
        <>
        <Header/>
        <Route {...rest} component={(props) => (
            <Component {...props}/>   
        )}/>
        </>
    );   
};

export default PublicRoute;