import React, {useEffect} from 'react';
import Header from './Header';

const SharpRogue = () => {
    useEffect(() => {
        document.body.className = "black-back";
        return () => {
           document.body.className = "";
        }
    }, []);
    return (
        <div
            className="project-page"
        >
            <Header/>
            <div id="sharp-rogue">
                <div className="project-title">
                    <h1>SHARP ROGUE</h1>
                    <p>terminal rogue like rpg</p>
                </div>
                <div id="intro-img">
                    <div className="img" id="img-1"/>
                    <div className="img" id="img-2"/>
                    <div className="img" id="img-3"/>
                </div>
                <div className="block">
                    <h1>Random Generation</h1>
                    <p>
                    Levels are generated randomly for each playthrough ensuring a unique experience every time
                    </p>
                </div>
                <div className="block">
                    <div className="img" id="img-1"/>
                    <h1>Kill Monsters</h1>
                    <p>
                    Fight off numerous foes along your way
                    </p>
                </div>
                <div className="block">
                    <div className="img" id="img-2"/>
                    <h1>Find Gear</h1>
                    <p>
                        Empower yourself with various weapons, armor and artifacts
                    </p>
                </div>
                <div className="block">
                    <div className="img" id="img-3"/>
                    <h1>Advance</h1>
                    <p>
                    As you progress through your game you encounter new monsters and find new items
                    </p>
                </div>
                <div className="block">
                    <div className="img" id="img-4"/>
                    <h1>Enhance Your Game</h1>
                    <p>
                    Thanks to the ease of extensibility you can add new surroundings, obstacles, enemies, and items to your game
                    </p>
                </div>
                <a href="https://github.com/zavidnyi/sharp-rogue">github</a>
            </div>
        </div>
    );
};

export default SharpRogue;