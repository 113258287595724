import React, {useState} from 'react';
import { useHistory } from 'react-router';

const Header = () => {
    const history = useHistory();
    const [focused, setFocused] = useState(false);
    const isMobile = window.innerWidth <= 480;
    return (
        <div
            className="header"
            onMouseEnter={()=>setFocused(true)}
            onMouseLeave={()=>setFocused(false)}
            onClick={()=>history.push('/')}
        >
            {(isMobile && "zavidnyi") || (focused && "zavidnyi") || "642620"}
        </div>
    );
};

export default Header;