import delaunay_triangs from '../files/delaunay_triangs.pdf';
import mis from '../files/discovering-signage-system-design.pdf';

const Talks = () => {
    return (
        <div className="talks-page">
            <div className="talk">
                <h1>A short proof of the toughness of Delaunay triangulations.</h1>
                <a id="link" href={delaunay_triangs} target="_blank" rel="noreferrer">presentation</a>
            </div>
            <div className="talk">
                <h1>Discovering signage system design</h1>
                <a id="link" href={mis} target="_blank" rel="noreferrer">presentation</a>
            </div>
        </div>
    );
};

export default Talks;